import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: String(process.env.REACT_APP_TAG_MANAGER_ID),
};

export function initTagManager() {
  TagManager.initialize(tagManagerArgs);
}

// USE THIS STANDARD
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?hl=it&client_type=gtag

export function fireTagManagerEvent(
  eventName: string,
  eventData: Record<string, any> = {},
) {
  // console.log(
  //   "fireTagManagerEvent: ",
  //   eventName,
  //   Object.keys(eventData).length > 0 ? "/ " + JSON.stringify(eventData) : "",
  // );
  if (!TagManager.dataLayer) {
    console.error(
      "TagManager dataLayer is not available. Make sure to initialize it first.",
    );
    return;
  }

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventData,
    },
  });
}

export function pushDataLayer(key: string, value: string | boolean) {
  if (!TagManager.dataLayer) {
    console.error(
      "TagManager dataLayer is not available. Make sure to initialize it first.",
    );
    return;
  }

  const dataLayer = {} as any;
  dataLayer[key] = value;

  TagManager.dataLayer({
    dataLayer,
  });
}
