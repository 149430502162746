import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { io } from "socket.io-client";

const socket = io("http://localhost:3333/search");
const search = async (placeId: string) => {
  socket.emit("search", {
    searchType: "city",
    placeId: placeId,
    pageNumber: 1,
    arrivalDate: "2025-03-03",
    departureDate: "2025-03-04",
    maxDistance: 10,
    currency: "EUR",
    adults: 3,
    childrenAge: [10, 4],
  });
};

export default function Test() {
  const [value, setValue] = useState<string>("");
  const [data, setData] = useState<{ label: string; googleId: string }[]>([]);
  const [hotels, setHotels] = useState<
    Record<string, { name: string; price?: number }>
  >({});

  const hotelsList = useMemo(() => Object.values(hotels), [hotels]);

  useEffect(() => {
    socket.connect();

    socket.on("hotels", (data: { data: { name: string; _id: string }[] }) => {
      setHotels(
        Object.fromEntries(data.data.map(({ _id, ...rest }) => [_id, rest])),
      );
    });

    socket.on("price", (priceData: { _id: string; price: number }) => {
      setHotels((hotels) => {
        const hotel = hotels[priceData._id];
        if (!hotel) return hotels;
        return {
          ...hotels,
          [priceData._id]: { ...hotel, price: priceData.price },
        };
      });
    });
  }, []);

  useEffect(() => {
    const autocomplete = async () => {
      const res = await axios.request({
        url: "directsearch/autocomplete",
        params: { query: value, language: "it" },
      });
      setData(res.data);
    };

    if (!!value) {
      try {
        autocomplete();
      } catch (e) {
        console.error(e);
      }
    }
  }, [value]);

  return (
    <>
      <div className="d-flex flex-column">
        My input:
        <input value={value} onChange={(e) => setValue(e.target.value)} />
        {data.length > 0 &&
          data.map((d) => (
            <button onClick={() => search(d.googleId)}>{d.label}</button>
          ))}
      </div>
      <div>
        {hotelsList.length > 0 &&
          hotelsList.map((h) => (
            <div className="container">
              <div className="row">
                <div className="col-sm">{h.name}</div>
                <div className="col-sm">{h.price}</div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
